import React from "react";
import {Market} from "@/components/Perps/types";
import { useHasMounted } from "@/hooks/useHasMounted";
import {useScreen} from "@/util/useScreen";
import {useModal} from "@/components/Modal";
import {AddressDisplay} from "@/components/Account/AddressDisplay";
import {IoChevronDown} from "react-icons/io5";
import {TokensTableModal} from "@/components/Perps/header/TokensTableModal";
import {FaCircleInfo} from "react-icons/fa6";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {isUsdEthMarket, isUsdToken} from "@/util/chainConstants";

export type Props = {
    market: Market;
    onChange: (market: Market) => void;
};

export const PerpTokenView = ({ market, onChange }: Props) => {
    const hasMounted = useHasMounted();
    const isDesktop = useScreen('sm');
    const modal = useModal();
    // const isUsd = isUsdToken(market.baseTokenAddress);
    const isUsdEth = isUsdEthMarket(market);
    const baseToken = market.pair.baseToken;
    const quoteToken = market.pair.quoteToken;

    if (!hasMounted) {
        return null;
    }

    return (
      <div className="">
          <div className="flex flex-row items-center gap-4">
            <div className="flex flex-row gap-2 items-center justify-between divide-neutral-content/20 hover:bg-gray-700 cursor-pointer rounded-md relative p-2"
                 onClick={modal.show}>
              <div className="flex flex-row gap-2 items-center">
                <img src={baseToken.imageUrl}
                     alt={baseToken.symbol}
                     className="w-6 h-6 rounded-full border border-glass-focus" />
                {isDesktop ? (
                  <div className="flex flex-row items-center gap-2">
                    <span className="text-sm lg:text-lg">
                      { isUsdEth ? "ETH" : (baseToken.symbol)}
                    </span>
                    <span className="text-neutral-content font-light text-xs md:text-sm">
                        { isUsdEth ? " / USDB" : (` / ${quoteToken.symbol}`)}
                      </span>
                  </div>
                ) : (
                  <div className="text-lg">
                    { isUsdEth ? "ETH" : (baseToken.symbol)}
                    <span className="text-neutral-content font-light text-xs md:text-sm">
                      { isUsdEth ? " / USDB" : (` / ${quoteToken.symbol}`)}
                    </span>
                  </div>
                )}
              </div>
              <IoChevronDown />
            </div>
            {isDesktop && <AddressDisplay
              displayIcon={true}
              address={baseToken.address}
              label="Block Explorer"
              className="text-neutral-content text-xs"
            />
            }
            {
              isDesktop && baseToken.twitterHandle &&
              <a href={`https://twitter.com/${baseToken.twitterHandle}`}
                 target="_blank"
                 rel="noopener noreferrer"
                 className="text-neutral-content text-xs bg-white/50 hover:bg-white p-1.5 rounded-full">
                <img src="/static/x_logo.png" className="w-2 h-2" alt={'twitter'}/>
              </a>
            }
            {
              isDesktop &&
              (
                baseToken.address.toLowerCase() === "0xf7bc58b8d8f97adc129cfc4c9f45ce3c0e1d2692".toLowerCase() || // WBTC
                baseToken.address.toLowerCase() === "0xd31a59c85ae9d8edefec411d448f90841571b89c".toLowerCase() // SOL
              ) &&
              <>
                <FaCircleInfo id="eth_btc_dominance" className="w-5 h-5 hover:scale-110" />
                <ReactTooltip
                  anchorSelect={`#eth_btc_dominance`}
                  id="tooltip_eth_btc_dominance"
                  place="bottom"
                  className="z-50 max-w-[300px]"
                  content={`${baseToken.symbol}/ETH dominance pair has ETH as the base currency. Therefore, the chart and profits won't always correspond with ${baseToken.symbol}/USD price movements.`}
                  style={{ backgroundColor: "#3b485f", color: "white", opacity: 1 }}
                />
              </>
            }
          </div>
          <TokensTableModal {...modal} onSelected={modal.hide} />
      </div>
    );
};
