import {PerpToken, SwapRequest, SwapQuote, Token, Market, SwapQuoteV2} from "@/components/Perps/types";
import React, {useEffect, useState} from "react";
import {SwapTokenInput} from "@/components/Swap/SwapTokenInput";
import {useQuery} from "@tanstack/react-query";
import {fetchBalances, fetchSwapQuote, fetchSwapQuoteV2} from "@/api/perpsDataFetcher";
import {MdSwapVert} from "react-icons/md";
import {useAccount} from "wagmi";
import {AddressZero} from "@ethersproject/constants";
import {formatUnits} from "viem";
import {SlippageButton} from "@/components/Perps/slippage/SlippageButton";
import {SummaryItem} from "@/components/Perps/SummaryItem";
import classNames from "classnames";
import {ChainItem, TransactionChain} from "@/components/Transactions/TransactionChain";
import {TokenAllowanceButton} from "@/components/ERC20/TokenAllowanceButton";
import {V3_SWAP_ROUTER_ADDRESS, WETH_ADDRESS} from "@/util/chainConstants";
import {BigNumber} from "ethers";
import {useUserSelection} from "@/contexts/UserSelectionContext";
import {Fractionalize} from "@/components/fractionalization/Fractionalize";
import {Button, ButtonType} from "@/components/Button";
import {Modal, useModal} from "@/components/Modal";
import {ErrorPanel} from "@/components/ErrorPanel";
import {SmartOrderView} from "@/components/Perps/SmartOrderView";

const getTokenAddresses = (swapRequest: SwapRequest) => {
  const inAddress = swapRequest.tokenIn;
  const outAddress = swapRequest.tokenOut;
  let token0;
  let token1;
  if (inAddress < outAddress) {
    token0 = inAddress;
    token1 = outAddress;
  } else {
    token0 = outAddress;
    token1 = inAddress;
  }
  return { token0, token1, inIsToken0: inAddress === token0 };
}

export const SwapWidget = ({initialMarket}: {initialMarket: Market}) => {
  const {address} = useAccount();
  const { userSelections } = useUserSelection();
  const fractionalizeModal = useModal();
  const [showInPrice, setShowInPrice] = useState(false);

  const [swapRequest, setSwapRequest] = useState<SwapRequest>({
    marketPairId: initialMarket.id,
    tokenIn: initialMarket.baseTokenAddress,
    tokenOut: initialMarket.quoteTokenAddress,
    amount: 0n,
    quoteType: "EXACT_IN",
    caller: address || AddressZero,
    recipient: address || AddressZero,
    maxSlippage: userSelections.slippage,
    orderDuration: 5 * 60, // 5 minutes
  });

  useEffect(() => setSwapRequest(s => ({...s, recipient: address || AddressZero})), [address]);

  const isExactIn = swapRequest.quoteType === "EXACT_IN";

  // const {error, isLoading: waitingForSignature, signTypedDataAsync} = useSignTypedData({
  //   domain,
  //   types,
  //   primaryType: "Bid",
  //   message: getBid(),
  // })

  const tokens = getTokenAddresses(swapRequest);

  const balanceQuery = useQuery({
    queryKey: ['balances', tokens.token0, tokens.token1],
    queryFn: async () => await fetchBalances(address!, [tokens.token0, tokens.token1]),
    enabled: !!address,
    gcTime: 10 * 1000, // 10 seconds
    refetchInterval: 10 * 1000, // 10 seconds
    staleTime: 10 * 1000, // 10 seconds
  });

  const swapQuote = useQuery({
    queryKey: ['swapRequest', swapRequest],
    queryFn: async () => await fetchSwapQuoteV2(swapRequest),
    enabled: swapRequest.amount > 0n,
    gcTime: 5 * 1000, // 20 seconds
    staleTime: 5 * 1000, // 20 seconds
    refetchInterval: 5 * 1000, // 20 seconds
  });

  // AllowanceTransfer.getPermitData({
  //   details: {
  //     token: swapRequest.in.address,
  //     amount: isExactIn ? swapRequest.amount : swapQuote.data?.quoteAmount || 0n,
  //     expiration: sigDeadline,
  //     nonce: allowanceProvider.getAllowanceData(),
  //   },
  //   sigDeadline,
  //   spender: UNIVERSAL_ROUTER
  // }, PERMIT_2, 1);

  const getValue = (tokenIn: boolean) => {
    if (tokenIn) {
      if (isExactIn) {
        return swapRequest.amount;
      }
      return swapQuote.data?.quoteAmount;
    } else {
      if (isExactIn) {
        return swapQuote.data?.quoteAmount;
      }
      return swapRequest.amount;
    }
  }

  const reverseSwap = () => {
    setSwapRequest({
      ...swapRequest,
      tokenIn: swapRequest.tokenIn,
      tokenOut: swapRequest.tokenOut,
      quoteType: swapRequest.quoteType === "EXACT_IN" ? "EXACT_OUT" : "EXACT_IN"
    });
  }

  const getPrice = () => {
    const quote = swapQuote.data;
    if (!quote || quote.quoteAmount === 0n) {
      return 0;
    }

    const isExactIn = quote.swapResponse.request.quoteType === "EXACT_IN";
    const inAmount = Number(formatUnits(isExactIn ? quote.swapResponse.request.amount : quote.quoteAmount, quote.swapResponse.tokenIn.decimals));
    const outAmount = Number(formatUnits(isExactIn ? quote.quoteAmount : quote.swapResponse.request.amount, quote.swapResponse.tokenOut.decimals));

    return inAmount / outAmount;
  }

  const price = getPrice();

  const chainItems: ChainItem[] = [];
  const isWrapping = swapRequest.tokenIn === AddressZero && swapRequest.tokenOut === WETH_ADDRESS;
  const isUnwrapping = swapRequest.tokenIn === WETH_ADDRESS && swapRequest.tokenOut === AddressZero;
  if (swapRequest.tokenIn !== AddressZero && !isWrapping && !isUnwrapping) {
    chainItems.push(
      {
        name: "Allow Token Transfers",
        description: "Allow the transfer of your token to the pool",
        stepRenderer: (onSuccess, onFailure) =>
          <TokenAllowanceButton
            autoStart={true}
            spender={V3_SWAP_ROUTER_ADDRESS || AddressZero}
            requestMaxValue={true}
            amount={swapRequest.quoteType === "EXACT_IN" ? BigNumber.from(swapRequest.amount) : BigNumber.from(swapQuote.data!.quoteAmount)}
            contractAddress={swapRequest.tokenIn}
            approvalChanged={approved => {
              if (approved) {
                onSuccess();
              }
            }}
            className="capitalize text-xs w-full"/>
      }
    );
    // if (CHAIN_ID === 1) {
    //   chainItems.push({
    //     name: "Sign",
    //     description: "Sign the transaction to swap your tokens.",
    //     stepRenderer: (onSuccess, onFailure) =>
    //       <Permit2Signer
    //         tokenAddress={swapRequest.tokenIn}
    //         autoStart={true}
    //         onFailure={onFailure}
    //         onSigned={permit2Permit => {
    //           setSwapRequest({...swapRequest, permit2Permit});
    //           onSuccess();
    //         }} />
    //   });
    // }
  }

  chainItems.push({
    name: "Swap",
    description: `Swap ${initialMarket.pair.baseToken.symbol} for ${initialMarket.pair.quoteToken.symbol}.`,
    buttonQuery: () => useQuery({
      queryKey: ["swapQuote_order", swapRequest],
      queryFn: async () => await fetchSwapQuoteV2({...swapRequest, recipient: address!}).then(d => ({
          id: "swap",
          loadingText: `Swapping ${initialMarket.pair.baseToken.symbol} for ${initialMarket.pair.quoteToken.symbol}...`,
          ...d.functionCallData,
          enabled: d.swapResponse.request.recipient !== AddressZero && d.swapResponse.request.recipient.toLowerCase() === address?.toLowerCase(),
          autoStart: true
      })),
      enabled: swapRequest.amount > 0n && address !== undefined,
      gcTime: 0,
    }),
  });

  const getBalance = (address: string) => {
    if (!balanceQuery.isLoading && balanceQuery.data) {
      if (tokens.token0 === address) {
        return balanceQuery.data[0];
      }
      return balanceQuery.data[1];
    }
    return 0n;
  }

  const hasEnoughBalance = () => {
    if (swapRequest.amount === 0n || balanceQuery.isLoading) {
      return true;
    }
    const isExactIn = swapRequest.quoteType === "EXACT_IN";
    if (!isExactIn && swapQuote.isLoading) {
      return true;
    }
    const valueToCheck = BigInt(isExactIn ? swapRequest.amount : swapQuote.data!.quoteAmount);
    const balance = BigInt(getBalance(swapRequest.tokenIn));
    return valueToCheck <= balance;
  }

  const anyHasV2 = () => {
    return initialMarket.exchange === "UNISWAP_V2" || initialMarket.exchange === "THRUSTER_V2";
  }


  const renderPrice = () => {
    let priceToRender = price;
    if (showInPrice) {
      priceToRender = 1 / price;
    }
    const symbol1 = showInPrice ? initialMarket.pair.baseToken.symbol : initialMarket.pair.baseToken.symbol;
    const symbol2 = showInPrice ?  initialMarket.pair.quoteToken.symbol :  initialMarket.pair.baseToken.symbol;

    return (
      <div className="text-sm cursor-pointer select-none hover:underline" onClick={() => setShowInPrice(s => !s)}>
        1 {symbol1} = {priceToRender.toLocaleString([], {maximumFractionDigits: priceToRender < 1 ? 7 : 3,})} {symbol2}
      </div>
    )
  }

  return (
    <div className="flex-grow flex flex-col gap-4">
      <div className="w-full flex flex-col items-end justify-end">
        <SlippageButton/>
        <div className="w-full relative flex flex-col gap-1">
          <SwapTokenInput
            balance={getBalance(swapRequest.tokenIn)}
            usdPrice={swapQuote.data ? swapQuote.data?.tokenToUsdPrice[swapRequest.tokenIn.toLowerCase()]! : 0}
            label="You pay"
            value={getValue(true)}
            isLoading={swapQuote.isLoading && swapRequest.quoteType === "EXACT_OUT"}
            onChange={(amount) => {
              setSwapRequest({
                ...swapRequest,
                amount: amount || 0n,
                quoteType: "EXACT_IN"
              })
            }}
            token={initialMarket.pair.baseToken}
            onTokenSelect={token => {
              setSwapRequest(s => ({
                ...s,
                in: token,
                out: token.address === s.tokenOut ? initialMarket.pair.baseToken : initialMarket.pair.quoteToken
              }));
            }}/>
          <SwapTokenInput
            balance={getBalance(swapRequest.tokenOut)}
            usdPrice={swapQuote.data?.tokenToUsdPrice[swapRequest.tokenOut.toLowerCase()] || 0}
            label="You receive"
            value={getValue(false)}
            isLoading={swapQuote.isLoading && swapRequest.quoteType === "EXACT_IN"}
            onChange={(amount) => {
              setSwapRequest({
                ...swapRequest,
                amount: amount || 0n,
                quoteType: "EXACT_OUT"
              })
            }}
            token={initialMarket.pair.quoteToken}
            onTokenSelect={token => {
              setSwapRequest(s => ({
                ...s,
                out: token,
                in: token.address === s.tokenIn ? initialMarket.pair.quoteToken : initialMarket.pair.baseToken
              }));
            }}
          />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="rounded-md border border-neutral-content/20 bg-base-200 hover:bg-base-300 cursor-pointer p-1"
                 onClick={reverseSwap}>
              <MdSwapVert size={24} />
            </div>
          </div>
        </div>
      </div>
      {
        anyHasV2()
          ? <ErrorPanel message="Most optimal route contains V2 pools and currently isn't supported by our smart router." />
          : <TransactionChain
              key={`swap_${swapRequest.tokenIn}_${swapRequest.tokenOut}_${swapRequest.recipient}_${swapRequest.quoteType}`}
              id={`swap_${swapRequest.tokenIn}_${swapRequest.tokenOut}_${swapRequest.recipient}_${swapRequest.quoteType}`}
              transactionChain={chainItems}
              enabled={address !== undefined && swapRequest.amount > 0 && swapQuote.isSuccess && hasEnoughBalance() && swapQuote.data.swapResponse.request.recipient !== AddressZero && swapQuote.data.swapResponse.request.recipient.toLowerCase() === address.toLowerCase()}
              onSuccess={() => balanceQuery.refetch()}
              title={hasEnoughBalance()
                ? (isWrapping
                  ? "Wrap ETH"
                  : (isUnwrapping
                    ? "Unwrap ETH" :
                    "Swap"))
                : "Insufficient Balance"}
            />
      }
      {
        (
          swapRequest.tokenIn.toLowerCase() === "0xc8d8d820f88df3bd48c4f8e95bca3b994b73c699".toLowerCase() ||
          swapRequest.tokenOut.toLowerCase() === "0xc8d8d820f88df3bd48c4f8e95bca3b994b73c699".toLowerCase()) &&
        <>
          <Button buttonType={ButtonType.YELLOW} onClick={fractionalizeModal.show}>
            Fractionalize Blastopians
          </Button>
          <Modal {...fractionalizeModal}>
            <Fractionalize />
          </Modal>
        </>
      }
      {
        !isWrapping && !isUnwrapping && swapQuote.isSuccess &&
        <div className="standard-stack !gap-2">
          {renderPrice()}
          <SummaryItem<SwapQuoteV2>
            label="Price Impact"
            className="text-sm"
            isLoading={swapQuote.isLoading}
            isError={swapQuote.isError}
            data={swapQuote.data}
            disabled={swapRequest.amount === 0n}
            tooltip="The difference your trade will make on the market price of the asset due to its size."
          >
            {
              quote =>
              <span className={classNames({
                "text-white": Math.abs(quote.priceImpact) < 0.02, // 5%
                "text-put": Math.abs(quote.priceImpact) >= 0.02 // 5%
              })}>
                {Math.abs(quote.priceImpact) < 0.0001 ? "~" : ""} {(quote.priceImpact * 100).toFixed(2)} %
              </span>
            }
          </SummaryItem>
          <SummaryItem<SwapQuoteV2>
            label="Fees"
            className="text-sm"
            isLoading={swapQuote.isLoading}
            isError={swapQuote.isError}
            data={swapQuote.data}
            disabled={swapRequest.amount === 0n}
          >
            { v => <span className="flex flex-row items-center text-call">No Fee</span> }
          </SummaryItem>
          <SummaryItem<SwapQuoteV2>
            label="Order Routing"
            className="text-sm"
            tooltip="The order route that will be executed to open your position."
            isLoading={swapQuote.isLoading}
            isError={swapQuote.isError}
            data={swapQuote.data}
            disabled={swapRequest.amount === 0n}
          >
            {swapQuote => <SmartOrderView swapResponse={swapQuote.swapResponse} />}
          </SummaryItem>
        </div>
      }
    </div>
  )
}
