import {PositionStatus} from "@/components/Perps/types";
import {formatUnits, parseUnits} from "viem";
import {formatEther} from "@/util/converters";

export const calculateCloseFee = (positionStatus: PositionStatus, netValue: number) => {
  const {position} = positionStatus;
  const isLong = position.side === 'LONG';

  if (isLong) {
    return (position.principal + netValue) * position.feesToBePaid / (position.feesToBePaid + position.downPayment + position.principal);
  } else {
    return (position.collateralAmount + netValue) * position.feesToBePaid / (position.feesToBePaid + position.collateralAmount);
  }
}


export const calculateLimitPriceFromPnl = (positionStatus: PositionStatus, roi: number) => {
  const { position,  markPrice, market} = positionStatus;
  const baseToken = market.pair.baseToken;
  const quoteToken = market.pair.quoteToken;

  if (roi === 0) {
    return markPrice;
  }
  const isLong = position.side === 'LONG';

  const interest = Number(formatUnits(positionStatus.interestPaid, isLong ? quoteToken.decimals : baseToken.decimals));

  const received = position.downPayment * (1 + roi / 100);
  const closeFee = position.feesToBePaid * received / position.downPayment;
  const netValue = received + closeFee;

  // const slippage = calculateSlippage(perpPositionStatus);
  if (isLong) {
    const payout = netValue + position.principal + interest;
    return payout / position.collateralAmount;
  } else {
    const payout = position.collateralAmount - netValue;
    return payout / (position.principal + interest);
  }
}

export const calculateSlippage = (positionStatus: PositionStatus) => {
  const {position, market, markPrice} = positionStatus;
  const baseToken = market.pair.baseToken;
  const quoteToken = market.pair.quoteToken;
  const isLong = position.side === 'LONG';

  const interest = Number(formatUnits(positionStatus.interestPaid, isLong ? quoteToken.decimals : baseToken.decimals));
  const received = Number(formatUnits(BigInt(positionStatus.netValue) + BigInt(positionStatus.fee), quoteToken.decimals));
  let currentMarketExecutionPrice;
  if (isLong) {
    const payout = received + position.principal + interest;
    currentMarketExecutionPrice = payout / position.collateralAmount;
  } else {
    const payout = position.collateralAmount - received;
    currentMarketExecutionPrice = payout / (position.principal + interest);
  }
  const slippage = currentMarketExecutionPrice / markPrice;
  return slippage < 1 ? 1 / slippage : slippage;
}

export const calculateAmounts = (positionStatus: PositionStatus, limitPrice: number | undefined) => {
  if (!limitPrice || limitPrice < 0) {
    return {
      estimatedPnL: 0,
      estimatedPayout: 0,
      estimatedROI: 0,
      makerAmount: 0n,
      takerAmount: 0n,
    }
  }

  // const slippage = calculateSlippage(perpPositionStatus);

  const {position, market} = positionStatus;
  const baseToken = market.pair.baseToken;
  const quoteToken = market.pair.quoteToken;
  const isLong = position.side === 'LONG';
  const interestPaid = Number(formatUnits(positionStatus.interestPaid, isLong ? quoteToken.decimals : baseToken.decimals));

  const makerAmount = position.collateralAmountRaw;
  const takerAmount = parseUnits(
    (isLong ? position.collateralAmount * limitPrice : position.collateralAmount / limitPrice).toString(),
    isLong ? quoteToken.decimals : baseToken.decimals);

  let netValue = isLong
    ? position.collateralAmount * limitPrice - position.principal - interestPaid
    : position.collateralAmount - (position.principal + interestPaid) * limitPrice;
  const closeFee = Math.max(0, netValue * position.feesToBePaid / (position.downPayment + position.feesToBePaid));
  const estimatedPayout = Math.max(0, netValue - closeFee);

  const estimatedPnL = estimatedPayout - position.downPayment;
  const estimatedROI = estimatedPnL / position.downPayment * 100;

  // console.log("--------------------")
  // console.log('position.collateralAmount', position.collateralAmount);
  // console.log('position.principal', position.principal);
  // console.log('interestPaid', interestPaid);
  // console.log('limitPrice', limitPrice);
  // console.log('netValue', netValue);
  //
  // console.log('estimatedPnL', estimatedPnL);
  // console.log('estimatedPayout', estimatedPayout);
  // console.log('estimatedROI', estimatedROI);
  // console.log('makerAmount', makerAmount);
  // console.log('takerAmount', takerAmount);
  // console.log('closeFee', closeFee);

  return {
    estimatedPnL,
    estimatedPayout,
    estimatedROI,
    makerAmount,
    takerAmount,
    closeFee
  };
};
