export const WasabiRouterAbi = [
  { "inputs": [], "stateMutability": "nonpayable", "type": "constructor" },
  {
    "inputs": [
      { "internalType": "address", "name": "target", "type": "address" }
    ],
    "name": "AddressEmptyCode",
    "type": "error"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "account", "type": "address" }
    ],
    "name": "AddressInsufficientBalance",
    "type": "error"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "implementation", "type": "address" }
    ],
    "name": "ERC1967InvalidImplementation",
    "type": "error"
  },
  { "inputs": [], "name": "ERC1967NonPayable", "type": "error" },
  { "inputs": [], "name": "FailedInnerCall", "type": "error" },
  { "inputs": [], "name": "InvalidInitialization", "type": "error" },
  { "inputs": [], "name": "InvalidPool", "type": "error" },
  { "inputs": [], "name": "InvalidSignature", "type": "error" },
  { "inputs": [], "name": "NotInitializing", "type": "error" },
  {
    "inputs": [
      { "internalType": "address", "name": "owner", "type": "address" }
    ],
    "name": "OwnableInvalidOwner",
    "type": "error"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "account", "type": "address" }
    ],
    "name": "OwnableUnauthorizedAccount",
    "type": "error"
  },
  { "inputs": [], "name": "ReentrancyGuardReentrantCall", "type": "error" },
  {
    "inputs": [
      { "internalType": "address", "name": "token", "type": "address" }
    ],
    "name": "SafeERC20FailedOperation",
    "type": "error"
  },
  { "inputs": [], "name": "UUPSUnauthorizedCallContext", "type": "error" },
  {
    "inputs": [
      { "internalType": "bytes32", "name": "slot", "type": "bytes32" }
    ],
    "name": "UUPSUnsupportedProxiableUUID",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [],
    "name": "EIP712DomainChanged",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint64",
        "name": "version",
        "type": "uint64"
      }
    ],
    "name": "Initialized",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "implementation",
        "type": "address"
      }
    ],
    "name": "Upgraded",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "UPGRADE_INTERFACE_VERSION",
    "outputs": [{ "internalType": "string", "name": "", "type": "string" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "eip712Domain",
    "outputs": [
      { "internalType": "bytes1", "name": "fields", "type": "bytes1" },
      { "internalType": "string", "name": "name", "type": "string" },
      { "internalType": "string", "name": "version", "type": "string" },
      { "internalType": "uint256", "name": "chainId", "type": "uint256" },
      {
        "internalType": "address",
        "name": "verifyingContract",
        "type": "address"
      },
      { "internalType": "bytes32", "name": "salt", "type": "bytes32" },
      { "internalType": "uint256[]", "name": "extensions", "type": "uint256[]" }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "contract IWasabiPerps",
        "name": "_longPool",
        "type": "address"
      },
      {
        "internalType": "contract IWasabiPerps",
        "name": "_shortPool",
        "type": "address"
      },
      {
        "internalType": "contract PerpManager",
        "name": "_manager",
        "type": "address"
      }
    ],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "longPool",
    "outputs": [
      { "internalType": "contract IWasabiPerps", "name": "", "type": "address" }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "contract IWasabiPerps",
        "name": "_pool",
        "type": "address"
      },
      {
        "components": [
          { "internalType": "uint256", "name": "id", "type": "uint256" },
          { "internalType": "address", "name": "currency", "type": "address" },
          {
            "internalType": "address",
            "name": "targetCurrency",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "downPayment",
            "type": "uint256"
          },
          { "internalType": "uint256", "name": "principal", "type": "uint256" },
          {
            "internalType": "uint256",
            "name": "minTargetAmount",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "expiration",
            "type": "uint256"
          },
          { "internalType": "uint256", "name": "fee", "type": "uint256" },
          {
            "components": [
              { "internalType": "address", "name": "to", "type": "address" },
              { "internalType": "uint256", "name": "value", "type": "uint256" },
              { "internalType": "bytes", "name": "data", "type": "bytes" }
            ],
            "internalType": "struct IWasabiPerps.FunctionCallData[]",
            "name": "functionCallDataList",
            "type": "tuple[]"
          }
        ],
        "internalType": "struct IWasabiPerps.OpenPositionRequest",
        "name": "_request",
        "type": "tuple"
      },
      {
        "components": [
          { "internalType": "uint8", "name": "v", "type": "uint8" },
          { "internalType": "bytes32", "name": "r", "type": "bytes32" },
          { "internalType": "bytes32", "name": "s", "type": "bytes32" }
        ],
        "internalType": "struct IWasabiPerps.Signature",
        "name": "_signature",
        "type": "tuple"
      }
    ],
    "name": "openPosition",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "contract IWasabiPerps",
        "name": "_pool",
        "type": "address"
      },
      {
        "components": [
          { "internalType": "uint256", "name": "id", "type": "uint256" },
          { "internalType": "address", "name": "currency", "type": "address" },
          {
            "internalType": "address",
            "name": "targetCurrency",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "downPayment",
            "type": "uint256"
          },
          { "internalType": "uint256", "name": "principal", "type": "uint256" },
          {
            "internalType": "uint256",
            "name": "minTargetAmount",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "expiration",
            "type": "uint256"
          },
          { "internalType": "uint256", "name": "fee", "type": "uint256" },
          {
            "components": [
              { "internalType": "address", "name": "to", "type": "address" },
              { "internalType": "uint256", "name": "value", "type": "uint256" },
              { "internalType": "bytes", "name": "data", "type": "bytes" }
            ],
            "internalType": "struct IWasabiPerps.FunctionCallData[]",
            "name": "functionCallDataList",
            "type": "tuple[]"
          }
        ],
        "internalType": "struct IWasabiPerps.OpenPositionRequest",
        "name": "_request",
        "type": "tuple"
      },
      {
        "components": [
          { "internalType": "uint8", "name": "v", "type": "uint8" },
          { "internalType": "bytes32", "name": "r", "type": "bytes32" },
          { "internalType": "bytes32", "name": "s", "type": "bytes32" }
        ],
        "internalType": "struct IWasabiPerps.Signature",
        "name": "_signature",
        "type": "tuple"
      },
      {
        "components": [
          { "internalType": "uint8", "name": "v", "type": "uint8" },
          { "internalType": "bytes32", "name": "r", "type": "bytes32" },
          { "internalType": "bytes32", "name": "s", "type": "bytes32" }
        ],
        "internalType": "struct IWasabiPerps.Signature",
        "name": "_traderSignature",
        "type": "tuple"
      },
      { "internalType": "uint256", "name": "_executionFee", "type": "uint256" }
    ],
    "name": "openPosition",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "proxiableUUID",
    "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "shortPool",
    "outputs": [
      { "internalType": "contract IWasabiPerps", "name": "", "type": "address" }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "newOwner", "type": "address" }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newImplementation",
        "type": "address"
      },
      { "internalType": "bytes", "name": "data", "type": "bytes" }
    ],
    "name": "upgradeToAndCall",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  }
] as const;
