import {Market, PositionStatus} from "@/components/Perps/types";
import React, {useState} from "react";
import {PositionHeader} from "@/components/Perps/positions/PositionHeader";
import {PnLDisplay} from "@/components/Perps/positions/PnLDisplay";
import {MdIosShare} from "react-icons/md";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {Button, ButtonType} from "@/components/Button";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {SummaryItem} from "@/components/Perps/SummaryItem";
import {SizeView} from "@/components/Perps/positions/SizeView";
import {MarketPriceView} from "@/components/Perps/market/MarketPriceView";

interface Props {
  positionStatus: PositionStatus;
  onMarketClicked: (market: Market) => any;
  handleShareClick: (position: PositionStatus) => any;
  setSelectedPosition: (position: PositionStatus) => any;
}

export const MobilePosition = ({positionStatus, onMarketClicked, handleShareClick, setSelectedPosition}: Props) => {
  const [showDetails, setShowDetails] = useState(false);

  const position = positionStatus.position;

  const id = `perp_share_${position.id}`;
  return (
    <div className="grid grid-cols-2 gap-4">
      <PositionHeader position={position}
                      market={positionStatus.market}
                      imageSize={14}
                      onMarketClicked={t => onMarketClicked(t)}/>
      <div className="flex flex-col items-end">
        <span className="text-neutral-content text-xs">PnL</span>
        <PnLDisplay positionStatus={positionStatus} className="!flex-col !items-end"/>
      </div>
      <div id={id}
           className="hover:scale-110 text-neutral-content hover:cursor-pointer hover:text-white flex flex-row items-center justify-start gap-2"
           onClick={() => handleShareClick(positionStatus)}>
        <MdIosShare size={22}/> Share
        <ReactTooltip
          anchorSelect={`#${id}`}
          id={`tooltip_${id}`}
          place="left"
          noArrow
          className="z-50"
          content="Share Your Position"
          style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
        />
      </div>
      <div className="flex flex-row items-center justify-end w-full">
        <Button
          buttonType={ButtonType.NEUTRAL}
          onClick={() => setSelectedPosition(positionStatus)}
          className="!py-1 !text-lg !font-normal !capitalize"
        >
          Close
        </Button>
      </div>
      <div className="col-span-2 standard-stack">
        <div className="w-full text-neutral-content flex flex-row items-center justify-center gap-2 text-sm"
             onClick={() => setShowDetails(s => !s)}>
          {showDetails ? <FaChevronUp /> : <FaChevronDown />}
          {showDetails ? "Hide Details" : "Show Details"}
        </div>
        {
          showDetails &&
          <>
            <SummaryItem<PositionStatus>
              label="Size"
              className="text-sm"
              isLoading={false}
              isError={positionStatus.hasError}
              data={positionStatus}
            >
              { v => <SizeView positionStatus={v} /> }
            </SummaryItem>
            <SummaryItem<PositionStatus>
              label="Entry Price"
              className="text-sm"
              isLoading={false}
              isError={positionStatus.hasError}
              data={positionStatus}
            >
              { v => <MarketPriceView market={v.market} price={v.position.entryPrice} />}
            </SummaryItem>
            <SummaryItem<PositionStatus>
              label="Index Price"
              className="text-sm"
              isLoading={false}
              isError={positionStatus.hasError}
              data={positionStatus}
            >
              { v => <MarketPriceView market={v.market} price={v.markPrice} />}
            </SummaryItem>
            <SummaryItem<PositionStatus>
              label="Liquidation Price"
              className="text-sm"
              isLoading={false}
              isError={positionStatus.hasError}
              data={positionStatus}
            >
              { v => <MarketPriceView market={v.market} price={v.liquidationPrice} />}
            </SummaryItem>
          </>
        }
      </div>
    </div>
  )
}
