import {PerpSide, PerpSideSelect} from "@/components/Perps/openPosition/PerpSideSelect";
import ReactSlider from "react-slider";
import {TokenInput} from "@/components/Perps/TokenInput";
import {capitalizeFirstLetter} from "@/util/helpers";
import {TokenOutput} from "@/components/Perps/TokenOutput";
import {formatUnits} from "viem";
import React, {useEffect, useState} from "react";
import {Market, PerpQuoteResponseV2, TokenType} from "@/components/Perps/types";
import {useQuery} from "@tanstack/react-query";
import {fetchQuote} from "@/api/perpsDataFetcher";
import Switch from "react-switch";
import {theme} from "../../../tailwind.config";
import {useQueryParam} from "@/hooks/useQueryParam";
import {OpenPositionButton} from "@/components/Perps/openPosition/OpenPositionButton";
import {useDebounce} from "@/util/useDebounce";
import {ErrorPanel} from "@/components/ErrorPanel";
import {WarningPanel} from "@/components/WarningPanel";
import {useAccount} from "wagmi";
import {SummaryItem} from "@/components/Perps/SummaryItem";
import {ga4AnalyticEvent} from "@/util/analytics";
import {OpenInterestView} from "@/components/Perps/OpenInterestView";
import {GeofenceLayout} from "@/layouts/GeofenceLayout";
import {useUserSelection} from "@/contexts/UserSelectionContext";
import {SlippageButton} from "@/components/Perps/slippage/SlippageButton";
import {SwapWidget} from "@/components/Swap/SwapWidget";
import {BLAST_TOKEN, isBlast, isEthMainnet} from "@/util/constants";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {BsLightningChargeFill} from "react-icons/bs";
import {FaLock} from "react-icons/fa";
import {useIsDesktop} from "@/hooks/useIsDesktop";
import {useTokenAmount} from "@/hooks/useTokenAmount";
import * as Tooltips from "@/util/tooltips";
import {isUsdEthMarket, isUsdToken, TOKENS} from "@/util/chainConstants";
import {EthValue} from "@/components/Perps/EthValue";
import {SmartOrderView} from "@/components/Perps/SmartOrderView";
import {MarketPriceView} from "@/components/Perps/market/MarketPriceView";
import {USDValue} from "@/components/Perps/USDValue";
import {MarketValueView} from "@/components/Perps/market/MarketValueView";
import classNames from "classnames";
import {TokenInfo} from "@/util/tokens";
import {AddressZero} from "@ethersproject/constants";
import {multicall} from "@wagmi/core";
import {config} from "../../../config";
import {MulticallAbi} from "@/contract/MulticallAbi";
import {WasabiVaultAbi} from "@/contract/WasabiVaultAbi";
import {ERC20Abi} from "@/contract/ERC20Abi";
import {TradeRewardsView} from "@/components/Perps/TradeRewardsView";

export type Props = {
  market: Market;
}

const otherSide = (side: PerpSide) => {
  if (side === 'long') {
    return 'short';
  }
  return 'long';
}

export const OpenPositionView = ({ market }: Props) => {
  const isDesktop = useIsDesktop();
  const baseToken = market.pair.baseToken;
  const quoteToken = market.pair.quoteToken;
  // is usd eth so we should reverse long/short and views
  const isUsdEth = isUsdEthMarket(market);
  const quoteIsUsd = isUsdToken(quoteToken.address);
  const tokenOptions = quoteIsUsd ? [TOKENS.usd, TOKENS.wUsd] : [TOKENS.eth, TOKENS.weth, TOKENS.wWeth];
  const { userSelections, handleSelection } = useUserSelection();
  const [paymentToken, setPaymentToken] = useState<TokenInfo>(tokenOptions.find(
      o => o.address === userSelections[quoteIsUsd ? "usdPaymentToken" : "ethPaymentToken"]) || tokenOptions[0]
  );
  const {address} = useAccount();


  const [side, setSide] = useQueryParam<PerpSide>('side', userSelections.side || 'long', v => (v === 'short' || v === 'swap') ? v : 'long');
  const [leverage, setLeverage] = useState<number>(market.maxLeverage);
  const tokenAmount = useTokenAmount(quoteToken.decimals);
  const downPayment = tokenAmount.value;
  const [showFullNftView, setShowFullNftView] = useState<boolean>(baseToken.tokenType === TokenType.FLC_FRACTION);
  const [speedUp, setSpeedUp] = useState<boolean>(false);
  const nftFractionDigits = isBlast ? 3 : 6;
  const outputToken = side === "long" ? baseToken : quoteToken;

  const balanceQuery = useQuery({
    queryKey: [address, ...tokenOptions.map(t => t.address)],
    enabled: !!address,
    refetchInterval: 10 * 1000, // 10 seconds
    queryFn: async () => await multicall(config, {
      contracts: tokenOptions.map(t => {
        if (t.address === AddressZero) {
          return {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            abi: MulticallAbi,
            functionName: "getEthBalance",
            args: [address]
          }
        }
        if (t.isVault) {
          return {
            address: t.address,
            abi: WasabiVaultAbi,
            functionName: "maxWithdraw",
            args: [address]
          }
        }
        return {
          address: t.address,
          abi: ERC20Abi,
          functionName: "balanceOf",
          args: [address]
        }
      })
    }).then(r => {
      // @ts-ignore
      return r.map(d => BigInt(d.result))
    })
  })

  const getBalance = () => {
    const index = tokenOptions.findIndex(t => t.address.toLowerCase() === paymentToken.address.toLowerCase());
    return balanceQuery.data ? balanceQuery.data[index] : 0n;
  }

  useEffect(() => {
    if (baseToken.tokenType !== TokenType.FLC_FRACTION && showFullNftView) {
      setShowFullNftView(false);
    }
    if (market.maxLeverage < leverage) {
      setLeverage(market.maxLeverage);
    }
  }, [market.pair.baseToken]);

  const {showInUsd} = useEthPrice();

  const debouncedLeverage = useDebounce(leverage, 100);
  const debouncedDownPayment = useDebounce(downPayment, 300);

  const { data, isError, isLoading, isSuccess } = useQuery({
    queryKey: ["quote", market.id, side, debouncedLeverage, debouncedDownPayment, speedUp],
    queryFn: async () => await fetchQuote(
      market.id,
      isUsdEth ? otherSide(side) : side,
      debouncedDownPayment || 0n,
      debouncedLeverage,
      speedUp ? 750 : userSelections.slippage,
      speedUp),
    enabled: side !== 'swap' && debouncedDownPayment !== undefined && debouncedDownPayment > 0,
    gcTime: 20 * 1000, // 20 seconds
    staleTime: 20 * 1000, // 20 seconds
    refetchInterval: 20 * 1000, // 20 seconds
  });

  const renderSwapView = () => {
    // if (isBlast) {
    //   return null;
    // }
    return (
      <SwapWidget initialMarket={market} />
    );
  }

  const getOutputSize = () => {
    if (!data) {
      return 0n;
    }
    return isUsdEth ? data.outputSizeInQuote : data.outputSize;
  }

  const getSymbol = () => {
    let symbol = baseToken.symbol;
    if (showFullNftView) {
      symbol = symbol.replace('μ', '');
    }
    if (symbol === "WETH" || isUsdEth) {
      symbol = "ETH";
    }
    return symbol;
  }

  const renderOpenPositionButton = () => {
    if (side === 'swap') {
      return null;
    }

    return (
      <div className="flex flex-col gap-1 w-full">
        <div className="p-2">
          <OpenInterestView market={market} />
        </div>
        <GeofenceLayout className="!text-sm md:p-4">
          <OpenPositionButton
            enabled={
              !!address &&
              !data?.errorMessage &&
              !!downPayment &&
              isSuccess &&
              downPayment > 0n &&
              downPayment <= getBalance()
            }
            marketId={market.id}
            market={market}
            paymentToken={paymentToken}
            maxSlippage={userSelections.slippage}
            side={isUsdEth ? otherSide(side) : side}
            downPayment={downPayment || 0n}
            leverage={leverage}
            speedUp={speedUp}
            onTransactionSuccess={() =>
              ga4AnalyticEvent(
                'perp',
                'open_position',
                `Position Opened: ${side}, ${leverage}x, ${downPayment} ETH`,
                {market: market.name, side: side, leverage: leverage, downPayment: downPayment})}
          />
        </GeofenceLayout>
      </div>
    )

  }

  const renderPerpView = () => {
    if (side === 'swap') {
      return renderSwapView();
    }
    const isBlastLong = side === "long" && baseToken.address.toLowerCase() === BLAST_TOKEN.toLowerCase();
    return (
      <>
        <div id="perp-section-input" className="standard-stack">
          <div className="w-full flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-2 text-xs tracking-wider font-bold text-neutral-content">
              {
                !isEthMainnet && <>
                  <BsLightningChargeFill />
                  <span>SPEED UP</span>
                  <Switch
                    checked={speedUp}
                    onColor={theme.extend.colors.call}
                    checkedIcon={false}
                    height={16}
                    width={32}
                    handleDiameter={16}
                    uncheckedIcon={false}
                    onChange={e => setSpeedUp(e.valueOf())}
                  />
                </>
              }
            </div>
            <div className="flex items-center">
              {
                speedUp
                  ? <span className="text-xs tracking-wider font-bold p-2 text-neutral-content flex flex-row gap-2 items-center">
                      7.5% SLIPPAGE <FaLock /></span>
                  : <SlippageButton />
              }
            </div>
          </div>
          <TokenInput {...tokenAmount}
                      title="Pay"
                      tokens={tokenOptions}
                      selectedToken={paymentToken}
                      onTokenSelected={(token) => {
                        setPaymentToken(token);
                        handleSelection(quoteIsUsd ? "usdPaymentToken" : "ethPaymentToken", token.address)
                      }}
                      currentBalance={getBalance()}
                      placeholder="0.0"/>
        </div>
        <div id="perp-section-output" className="standard-stack">
          <TokenOutput
            title={
              <div className="flex flex-row items-center gap-2">
              {capitalizeFirstLetter(side)}
                {
                  data?.outputSizeInQuote &&
                  <div className="flex flex-row items-center">
                    {
                      isUsdEth
                        ? <USDValue value={Number(formatUnits(data.outputSize, baseToken.decimals))} />
                        : quoteIsUsd
                          ? <USDValue value={Number(formatUnits(data.outputSizeInQuote, quoteToken.decimals))} />
                          : <EthValue value={data.outputSizeInQuote} iconSize={12} />
                    }
                  </div>
                }
              </div>
            }
            displayTokenSelect={true}
            value={getOutputSize()}
            isLoading={isLoading}
            placeholder="0.0"
            imageUrl={baseToken.imageUrl}
            symbol={getSymbol()}
            tokenDecimals={baseToken.decimals + (showFullNftView ? 1 : 0) * nftFractionDigits} />
        </div>
        <div id="perp-section-leverage" className="standard-stack py-2">
          <div className="text-md font-light">Set Leverage</div>
          <div className="flex flex-row gap-3 items-center justify-between">
            <ReactSlider
              className="flex items-center justify-center w-full flex-grow"
              thumbClassName="customSlider-thumb"
              trackClassName="customSlider-track"
              defaultValue={1.1}
              min={1.1}
              step={0.1}
              max={market.maxLeverage}
              renderThumb={
                baseToken.address === '0x58cb30368ceb2d194740b144eab4c2da8a917dcb' ? (
                  (props, stats) =>
                    <div {...props}>
                      <img src="https://wasabi-public.s3.amazonaws.com/zyn_slider.webp" alt="zyn" className="w-[24px] h-[24px] rounded-full" />
                    </div>)
                  : undefined }
              value={leverage}
              onChange={setLeverage}
            />
            <span className="text-2xl min-w-[60px] text-right">{leverage}{baseToken.address === '0x58cb30368ceb2d194740b144eab4c2da8a917dcb' ? <span className="text-sm">mg</span> : 'x'}</span>
          </div>
        </div>
        <div id="perp-section-summary" className="standard-stack">
          {
            baseToken.nftAddress &&
              <div className="flex flex-row items-center gap-2 text-sm">
              <span>
                View as Full NFT
              </span>
              <Switch checked={showFullNftView}
                      onColor={theme.extend.colors.call}
                      checkedIcon={false}
                      height={16}
                      width={32}
                      handleDiameter={16}
                      uncheckedIcon={false}
                      onChange={e => setShowFullNftView(e.valueOf())}/>
            </div>
          }
          <SummaryItem<Market>
            label={<div className="flex flex-row gap-2 items-end">Rewards
              <TradeRewardsView market={market} side={side} />
            </div>}
            className="text-sm"
            isLoading={false}
            isError={false}
            data={market}
          >
            { v => <div className="flex flex-row gap-1 items-center">
              <span className="text-[#FCFC05] text-xl">{leverage * (isBlastLong ? 2 : 1)}x</span>
            </div>
            }
          </SummaryItem>
          <SummaryItem<PerpQuoteResponseV2>
            label="Entry Price"
            className="text-sm"
            isLoading={isLoading}
            isError={isError}
            data={data}
            disabled={!downPayment}
            tooltip={Tooltips.OPEN_POSITION_ENTRY_PRICE}
          >
            { v => <MarketPriceView price={v.entryPrice} market={market} /> }
          </SummaryItem>
          <SummaryItem<PerpQuoteResponseV2>
            label="Price Impact"
            className="text-sm"
            isLoading={isLoading}
            isError={isError}
            data={data}
            disabled={!downPayment}
            tooltip={Tooltips.OPEN_POSITION_PRICE_IMPACT}
          >
            {
              v =>
                <span className={classNames({
                  "text-white": Math.abs(v.swapResponse.priceImpact) < 0.02, // 5%
                  "text-put": Math.abs(v.swapResponse.priceImpact) >= 0.02 // 5%
                })}>
                {Math.abs(v.swapResponse.priceImpact) < 0.0001 ? "~" : ""} {(v.swapResponse.priceImpact * 100).toFixed(2)} %
              </span>
            }
          </SummaryItem>
          <SummaryItem<PerpQuoteResponseV2>
            label="Liquidation Price"
            className="text-sm"
            isLoading={isLoading}
            isError={isError}
            data={data}
            disabled={!downPayment}
            tooltip={Tooltips.OPEN_POSITION_LIQ_PRICE}
          >
            { v => <MarketPriceView price={v.liquidationPrice} market={market} /> }
          </SummaryItem>
          <SummaryItem<PerpQuoteResponseV2>
            label="Fees"
            className="text-sm"
            isLoading={isLoading}
            isError={isError}
            data={data}
            disabled={!downPayment}
            tooltip={Tooltips.OPEN_POSITION_FEES}
          >
            { v => <MarketValueView market={market} value={v.fee} /> }
          </SummaryItem>
          <SummaryItem<PerpQuoteResponseV2>
            label="Borrow Rate"
            className="text-sm"
            isLoading={isLoading}
            isError={isError}
            data={data}
            disabled={!downPayment}
            tooltip={Tooltips.OPEN_POSITION_BORROW_RATE}
          >
            {
              v =>
                <span className="text-white" id="borrow_rate">
                  {(v.hourlyBorrowFee * 100).toFixed(4)}% / 1h
                </span>
            }
          </SummaryItem>
          <SummaryItem<PerpQuoteResponseV2>
            label="Order Routing"
            className="text-sm"
            tooltip={Tooltips.OPEN_POSITION_ORDER_ROUTING}
            isLoading={isLoading}
            isError={isError}
            data={data}
            disabled={!downPayment}
          >
            {v => <SmartOrderView swapResponse={v.swapResponse} />}
          </SummaryItem>
        </div>
        { isDesktop && renderOpenPositionButton() }
        {
          (address && downPayment && getBalance() < downPayment) ?
            <ErrorPanel message="Insufficient Balance" /> :
            <>
              { data?.errorMessage && <WarningPanel message={data.errorMessage} /> }
              { isError && <ErrorPanel message="Something went wrong" /> }
            </>
        }
        {
          speedUp ? <WarningPanel className="text-xs p-0" message="Smart routing is disabled, you might experience higher slippage." /> : <div></div>
        }
      </>
    )
  }

  return (
    <div className="h-full flex flex-col justify-start no-scrollbar">
      <PerpSideSelect side={side} onChange={(newSide) => {
          setSide(newSide);
          handleSelection('side', newSide);
      }} />
      <div className="flex-grow py-2 px-4 flex flex-col gap-2 overflow-y-scroll no-scrollbar">
        {renderPerpView()}
      </div>
      { !isDesktop && renderOpenPositionButton() }
    </div>
  )
}
