import React from "react";
import {SwapResponse} from "@/components/Perps/types";
import ReactImageFallback from "react-image-fallback";
import {Tooltip as ReactTooltip} from "react-tooltip";

export const SmartOrderView = ({swapResponse}: { swapResponse: SwapResponse }) => {
  const renderRoute = () => {
    return (
      <div className="standard-stack p-2">
        {swapResponse.tradePaths.map((step, index) => (
          <div className="flex flex-row items-center gap-2 text-white" key={`trade_path_${index}`}>
            <span>{`${step.percent}%`} ({step.protocol})</span>
            <div className="relative flex-grow">
              <div className="flex flex-row items-center justify-between gap-2">
                <ReactImageFallback
                  src={swapResponse.tokenIn.imageUrl || "https://wasabi-public.s3.amazonaws.com/tokens/weth.png"}
                  fallbackImage="https://wasabi-public.s3.amazonaws.com/tokens/weth.png"
                  initialImage="/static/dkoda_loading.svg"
                  className="w-6 h-6 rounded-full z-50"
                />
                {
                  Array(step.path.length - 1).fill(0).map((_, i) => {
                    const token = step.path[i];
                    return (
                      <div key={`${token}-${i}`}
                           className="text-center text-[10px] bg-base-100 p-1 z-50 border rounded-md">
                        {`${token}/${step.path[i + 1]} `}
                      </div>
                    );
                  })
                }
                <ReactImageFallback
                  src={swapResponse.tokenOut.imageUrl || "https://wasabi-public.s3.amazonaws.com/tokens/weth.png"}
                  fallbackImage="https://wasabi-public.s3.amazonaws.com/tokens/weth.png"
                  initialImage="/static/dkoda_loading.svg"
                  className="w-6 h-6 rounded-full z-50"
                />
              </div>
              <div
                className="absolute flex items-center top-5 left-0 right-0 text-neutral-content/50 text-sm z-30 border-neutral-content border-b border-dotted">
              </div>
            </div>
          </div>
        ))}
        <hr className="border-neutral-content/20"/>
        <span className="text-xs text-neutral-content max-w-[350px]">
              We compute the smartest trade route to maximize your position size and reduce unnecessary price impact
            </span>
      </div>
    );
  }

  return (<div>
      <span id="order_routing" className="text-white cursor-help">
        WasabiSmartRouter
      </span>
      {
        <div className="text-sm text-neutral-content">
          <ReactTooltip
            anchorSelect="#order_routing"
            id="order_routing_tooltip"
            noArrow={true}
            place="top"
            className="z-50 text-lg font-bold !rounded-md border-2 border-white"
            style={{
              opacity: 1,
              backgroundColor: "#090f17",
              borderRadius: 20,
            }}
          >
            {renderRoute()}
          </ReactTooltip>
        </div>
      }
    </div>
  )
};
