import {Market, PerpQuoteRequestV2} from "@/components/Perps/types";
import {ChainItem, TransactionChain} from "@/components/Transactions/TransactionChain";
import {useQuery} from "@tanstack/react-query";
import {fetchOrderV2} from "@/api/perpsDataFetcher";
import {TransactionButtonProps} from "@/components/Transactions/TransactionButton";
import {WasabiPerpAbi} from "@/contract/WasabiPerpAbi";
import {WasabiRouterAbi} from "@/contract/WasabiRouterAbi";
import React from "react";
import {TransactionReceipt} from "viem";
import {
  isUsdEthMarket,
  WASABI_LONG_POOL_ADDRESS,
  WASABI_SHORT_POOL_ADDRESS,
  WASABI_ROUTER_ADDRESS
} from "@/util/chainConstants";
import {TransactionType} from "@/types/types";
import {TokenAllowanceButton} from "@/components/ERC20/TokenAllowanceButton";
import {toBN} from "@/util/converters";
import {TokenInfo} from "@/util/tokens";
import {AddressZero} from "@ethersproject/constants";

export interface Props extends PerpQuoteRequestV2 {
  market: Market;
  paymentToken: TokenInfo;
  enabled: boolean;
  onTransactionSuccess: (lastReceipt?: TransactionReceipt) => any;
}

export const OpenPositionButton = (props: Props) => {
  const {side, market, paymentToken, downPayment, leverage, marketId, maxSlippage, speedUp, enabled, onTransactionSuccess} = props;
  const poolAddress = side === "long" ? WASABI_LONG_POOL_ADDRESS : WASABI_SHORT_POOL_ADDRESS;
  const isUSDB = isUsdEthMarket(market);
  const isEthPayment = paymentToken.address === AddressZero;
  let displaySide = side;
  if (isUSDB) {
    displaySide = side === "long" ? "short" : "long";
  }
  let pair = market.name;
  if (isUSDB) {
    pair = "ETHUSD";
  }

  const transactionChain: ChainItem[] = [];
  if (paymentToken.address !== AddressZero && !paymentToken.isVault) {
    transactionChain.push(
      {
        name: "Allow Token Transfers",
        description: "Allow the transfer of your token to the pool",
        stepRenderer: (onSuccess, onFailure) =>
          <TokenAllowanceButton
            autoStart={true}
            spender={poolAddress}
            requestMaxValue={true}
            amount={toBN(downPayment)}
            contractAddress={paymentToken.address}
            approvalChanged={approved => {
              if (approved) {
                onSuccess();
              }
            }}
            className="capitalize text-xs w-full"/>
      }
    );
  }

  transactionChain.push(
    {
      name: "Open Position",
      description: (
        <div className="text-white text-xl">
          Opening <span className={displaySide === "long" ? "text-call" : "text-put"}>{leverage}x {displaySide.toUpperCase()}</span> on {pair}
        </div>
      ),
      buttonQuery: () => useQuery({
        queryKey: ["open_position", side, leverage, downPayment, marketId, speedUp],
        queryFn: async () => {
          const order = await fetchOrderV2(props);
          const buttonProps: TransactionButtonProps = {
            id: "open_position",
            loadingText: "Opening position...",
            addressOrName: paymentToken.isVault ? WASABI_ROUTER_ADDRESS : poolAddress,
            functionName: "openPosition",
            contractInterface: paymentToken.isVault ? WasabiRouterAbi : WasabiPerpAbi,
            args: paymentToken.isVault ? [poolAddress, order.request, order.signature] : [order.request, order.signature],
            overrides: {
              value: isEthPayment ? (BigInt(order.request.downPayment) + BigInt(order.request.fee)) : BigInt(0n)
            },
            enabled: enabled
          };
          return buttonProps;
        },
        gcTime: 0,
      })
    }
  );

  return (
    <TransactionChain
      key={`open_${market.id}_${side}`}
      id="open_position"
      title="Open Position"
      className="!rounded-none md:!rounded-md !py-6 md:!py-3"
      onSuccess={r => r !== undefined && onTransactionSuccess(r)}
      transactionChain={transactionChain}
      enabled={enabled}
      type={TransactionType.TRADE}
    />
  )
}
