import {useQuery} from "@tanstack/react-query";
import {fetchCloseOrder, fetchCloseQuote} from "@/api/perpsDataFetcher";
import {
  CloseQuoteResponse,
  PerpTradeShare,
  PositionStatus
} from "@/components/Perps/types";
import React, {useState} from "react";
import {BsFillLightningChargeFill, BsLightningChargeFill} from "react-icons/bs";
import {ChainItem, TransactionChain} from "@/components/Transactions/TransactionChain";
import {TransactionButtonProps} from "@/components/Transactions/TransactionButton";
import {WasabiPerpAbi} from "@/contract/WasabiPerpAbi";
import {SummaryItem} from "@/components/Perps/SummaryItem";
import {ga4AnalyticEvent} from "@/util/analytics";
import moment from "moment/moment";
import {isEthMainnet} from "@/util/constants";
import {SmartOrderView} from "@/components/Perps/SmartOrderView";
import {FaChevronDown, FaChevronUp, FaLock} from "react-icons/fa";
import {SlippageButton} from "@/components/Perps/slippage/SlippageButton";
import {useUserSelection} from "@/contexts/UserSelectionContext";
import classNames from "classnames";
import {PayoutType, TransactionType} from "@/types/types";
import Switch from "react-switch";
import {theme} from "../../../tailwind.config";
import {WarningPanel} from "@/components/WarningPanel";
import {
  isUsdEthMarket,
  isUsdToken, TOKENS,
  WASABI_LONG_POOL_ADDRESS,
  WASABI_SHORT_POOL_ADDRESS
} from "@/util/chainConstants";
import * as Tooltips from "@/util/tooltips";
import {MarketPriceView} from "@/components/Perps/market/MarketPriceView";
import {MarketValueView} from "@/components/Perps/market/MarketValueView";

export interface Props {
  positionStatus: PositionStatus;
  onSuccess: (pnlPositive: boolean, shareTrade: PerpTradeShare) => void;
}

export const MarketClosePositionView = ({positionStatus, onSuccess}: Props) => {
  const {userSelections} = useUserSelection();
  const position = positionStatus.position;
  const positionId = position.id;

  const [detailsExpanded, setDetailsExpanded] = useState<boolean>(false);
  const [eject, setEject] = useState<boolean>(false);

  const query = useQuery({
    queryKey: ["quoteClose", positionId, userSelections.slippage, eject],
    queryFn: async () => await fetchCloseQuote(positionId, eject ? 750 : userSelections.slippage, eject),
    staleTime: 4 * 1000, // 4 seconds
    refetchInterval: 4 * 1000, // 4 seconds
    gcTime: 4 * 1000 // 4 seconds
  });

  const isUSDB = isUsdToken(position.side === "LONG" ? position.collateralCurrencyAddress : position.currencyAddress);
  const isUsdQuote = isUsdToken(positionStatus.market.pair.quoteToken.address);
  let displaySide = position.side;
  if (isUSDB) {
    displaySide = position.side === "LONG" ? "SHORT" : "LONG";
  }
  let pair = positionStatus.market.pair.baseToken.symbol + "/" + positionStatus.market.pair.quoteToken.symbol;
  if (isUSDB) {
    pair = "ETH/USDB";
  }
  const vaultToken = isUsdQuote ? TOKENS.wUsd : TOKENS.wWeth;

  const transactionChain: ChainItem[] = [
    {
      name: "Close Position",
      description: (
        <div className="text-white text-xl">
          Closing <span className={displaySide === "LONG" ? "text-call" : "text-put"}>{position.leverage}x {displaySide.toUpperCase()}</span> on {pair}
        </div>
      ),
      buttonQuery: () => useQuery({
        queryKey: ["close_position", positionId, userSelections.slippage],
        queryFn: async () => {
          const order = await fetchCloseOrder(positionId, eject ? 750 : userSelections.slippage, eject);
          const buttonProps: TransactionButtonProps = {
            id: "close_position",
            loadingText: "Closing position...",
            addressOrName: query.data?.position.side === "LONG" ? WASABI_LONG_POOL_ADDRESS : WASABI_SHORT_POOL_ADDRESS,
            functionName: "closePosition",
            contractInterface: WasabiPerpAbi,
            args: [PayoutType.VAULT_DEPOSIT, order.request, order.signature],
            enabled: true
          };
          return buttonProps;
        },
        gcTime: 0,
      })
    }
  ];

  const getSide = () => {
    if (!query.data) {
      return undefined;
    }
    const data = query.data;
    let side;
    if (isUsdEthMarket(data.market)) {
      side = data.position.side === "LONG" ? "SHORT" : "LONG";
    } else {
      side = data.position.side;
    }
    return <span className={classNames({
      "text-call": side === "LONG",
      "text-put": side === "SHORT"
    })}>{side}</span>
  }

  const getTokenName = () => {
    if (!query.data) {
      return undefined;
    }
    const data = query.data;
    if (isUsdEthMarket(data.market)) {
      return "ETH";
    }
    return query.data.market.pair.baseToken.symbol;
  }

  const getIsPnlPositive = () => {
    if (!query.data) {
      return false;
    }
    return BigInt(query.data.payout) > BigInt(query.data.position.downPaymentRaw);
  }

  return (
    <div className="standard-stack p-2">
      <div className="standard-stack">
        <div className="flex flex-row gap-1 items-center justify-between">
          <div className="flex flex-col">
            {
              !isEthMainnet &&
              <div className="flex flex-row items-center gap-2 text-sm text-neutral-content">
                <BsLightningChargeFill />
                <span>SPEED UP</span>
                <Switch checked={eject}
                        onColor={theme.extend.colors.call}
                        checkedIcon={false}
                        height={16}
                        width={32}
                        handleDiameter={16}
                        uncheckedIcon={false}
                        onChange={e => setEject(e.valueOf())}/>
              </div>
            }
          </div>
          {
            eject
              ? <span className="text-xs tracking-wider font-bold p-2 text-neutral-content flex flex-row gap-2 items-center">
                7.5% SLIPPAGE <FaLock /></span>
              : <SlippageButton />
          }
        </div>
        <hr className="border-neutral-content/50 w-full"/>
        <div className="!cursor-pointer hover:text-white" onClick={() => setDetailsExpanded(a => !a)}>
          <SummaryItem<CloseQuoteResponse>
            label={
              <div className="flex flex-row gap-2 items-center !cursor-pointer hover:text-white">
                {detailsExpanded ? <FaChevronUp /> : <FaChevronDown />} Details
              </div>
            }
            isLoading={query.isLoading}
            isError={query.isError}
            data={query.data}
          >
            {v => (
              <div className="flex flex-row gap-2 items-center">
                <img src={v.market.pair.baseToken.imageUrl} alt={v.market.pair.baseToken.symbol} className="w-6 h-6 rounded-full border border-glass-focus" />
                <span>{getTokenName()} {v.position.leverage}x {getSide()}</span>
              </div>
            )}
          </SummaryItem>
        </div>
        {
          detailsExpanded && <>
            <hr className="border-neutral-content/50 w-full"/>
            <SummaryItem<CloseQuoteResponse>
              label="Opened"
              isLoading={query.isLoading}
              isError={query.isError}
              data={query.data}
            >
              {v => <span className="capitalize">{moment.unix(v.position.openTimestamp).fromNow()}</span>}
            </SummaryItem>
            <SummaryItem<CloseQuoteResponse>
              label="Entry Price"
              isLoading={query.isLoading}
              isError={query.isError}
              data={query.data}
              tooltip={Tooltips.CLOSE_POSITION_ENTRY_PRICE}
            >
              {v => <MarketPriceView price={v.position.entryPrice} market={v.market} iconSize={12} />}
            </SummaryItem>
            <SummaryItem<CloseQuoteResponse>
              label="Estimated Exit Price"
              isLoading={query.isLoading}
              isError={query.isError}
              data={query.data}
              tooltip={Tooltips.CLOSE_POSITION_EST_EXIT_PRICE}
            >
              {v => <MarketPriceView price={v.sellPrice} market={v.market} iconSize={12} />}
            </SummaryItem>
            <SummaryItem<CloseQuoteResponse>
              label="Liquidation Price"
              isLoading={query.isLoading}
              isError={query.isError}
              data={query.data}
              tooltip={Tooltips.CLOSE_POSITION_LIQ_PRICE}
            >
              {v => <MarketPriceView price={v.liquidationPrice} market={v.market} iconSize={12} />}
            </SummaryItem>
            <SummaryItem<CloseQuoteResponse>
              label="Total Interest Paid"
              isLoading={query.isLoading}
              isError={query.isError}
              data={query.data}
              tooltip={Tooltips.CLOSE_POSITION_INTEREST_PAID}
            >
              {v => <MarketValueView value={v.interestOwedInQuote} market={v.market} />}
            </SummaryItem>
          </>
        }
        {/*<SummaryItem<CloseQuoteResponse>*/}
        {/*  label="Position"*/}
        {/*  isLoading={query.isLoading}*/}
        {/*  isError={query.isError}*/}
        {/*  data={query.data}*/}
        {/*>*/}
        {/*  {v => (*/}
        {/*    <div className="flex flex-row gap-2 items-center">*/}
        {/*      <img src={v.token.imageUrl} alt={v.token.symbol} className="w-6 h-6 rounded-full border border-glass-focus" />*/}
        {/*      <span>{v.token.name} {v.position.leverage}x {v.position.side}</span>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</SummaryItem>*/}
        {/*<SummaryItem<CloseQuoteResponse>*/}
        {/*  label="Side"*/}
        {/*  isLoading={query.isLoading}*/}
        {/*  isError={query.isError}*/}
        {/*  data={query.data}*/}
        {/*>*/}
        {/*  {v => v.position.side}*/}
        {/*</SummaryItem>*/}
        {/*<SummaryItem<CloseQuoteResponse>*/}
        {/*  label="Leverage"*/}
        {/*  isLoading={query.isLoading}*/}
        {/*  isError={query.isError}*/}
        {/*  data={query.data}*/}
        {/*>*/}
        {/*  {*/}
        {/*    v => <span>*/}
        {/*      {getLeverage(v.position)}x*/}
        {/*    </span>*/}
        {/*  }*/}
        {/*</SummaryItem>*/}
        <hr className="border-neutral-content/50 w-full"/>
        <SummaryItem<CloseQuoteResponse>
          label="Order Routing"
          tooltip={Tooltips.CLOSE_POSITION_ORDER_ROUTING}
          isLoading={query.isLoading}
          isError={query.isError}
          data={query.data}
        >
          {v => <SmartOrderView swapResponse={v.swapResponse} />}
        </SummaryItem>
        <hr className="border-neutral-content/50 w-full"/>
        <SummaryItem<CloseQuoteResponse>
          label="Down Payment"
          isLoading={query.isLoading}
          isError={query.isError}
          data={query.data}
        >
          {v => <MarketValueView value={v.position.downPaymentRaw} market={v.market} />}
        </SummaryItem>
        <SummaryItem<CloseQuoteResponse>
          label="Net Value"
          isLoading={query.isLoading}
          isError={query.isError}
          data={query.data}
          tooltip={Tooltips.CLOSE_POSITION_NET_VALUE}
        >
          {v => <MarketValueView value={BigInt(v.payout) + BigInt(v.fee)} market={v.market} />}
        </SummaryItem>
        <SummaryItem<CloseQuoteResponse>
          label="PnL"
          isLoading={query.isLoading}
          isError={query.isError}
          data={query.data}
          tooltip={Tooltips.CLOSE_POSITION_PNL}
        >
          {v =>
            <MarketValueView
              market={v.market}
              className="flex-row-reverse	"
              value={BigInt(v.payout) + BigInt(v.fee) - BigInt(v.position.downPaymentRaw)}
              valueForPercent={BigInt(v.payout) + BigInt(v.fee)}
              original={BigInt(v.position.downPaymentRaw)}
            />
          }
        </SummaryItem>
        <hr className="border-neutral-content/50 w-full"/>
        <SummaryItem<CloseQuoteResponse>
          label="Close Fees"
          isLoading={query.isLoading}
          isError={query.isError}
          data={query.data}
          tooltip={Tooltips.CLOSE_POSITION_FEES}
        >
          {v => <MarketValueView market={v.market} value={-BigInt(v.fee)} />}
        </SummaryItem>
        <SummaryItem<CloseQuoteResponse>
          label={
            <span className="flex flex-row gap-2 items-center">
              Receive as <img src={vaultToken?.imageUrl} className="w-7 h-7 rounded-full" alt={vaultToken?.symbol} /> {vaultToken?.symbol}
            </span>
          }
          isLoading={query.isLoading}
          isError={query.isError}
          data={query.data}
          tooltip={
            <>
              <p className="text-sm font-bold text-left">Deposit into Vault</p>
              <p className="text-xs text-neutral-content text-left">
                Earn yield, points & more when receiving assets into your vault.
              </p>
            </>
          }
        >
          {v => <MarketValueView market={v.market} value={BigInt(v.payout)}/>}
        </SummaryItem>
      </div>
      <TransactionChain
        id='close_position'
        enabled={query.isSuccess}
        title={<><BsFillLightningChargeFill /> Close Position</>}
        transactionChain={transactionChain}
        type={TransactionType.TRADE}
        onSuccess={() => {
          ga4AnalyticEvent(
            'perp',
            'close_position',
            `Position Closed: ${positionId}`, {positionId: positionId}
          );
          onSuccess(getIsPnlPositive(), {
            id: query.data!.position.id!,
            token: query.data!.market.pair.baseToken,
            side: query.data!.position.side,
            action: "CLOSE"
          });
        }} />
      {
        eject ? <WarningPanel className="text-xs p-0" message="Smart routing is disabled, you might experience higher slippage." /> : <div></div>
      }
    </div>
  );
}
